import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { loadingState } from "../../utils/atom";
import LoadingIcon from "../../assets/images/loading.png";

const Loading = () => {
  const [modal, setModal] = useRecoilState(loadingState);
  const [os, setOs] = useState("pc");

  useEffect(() => {
    const varUA = navigator.userAgent;

    if (varUA.match("coffik/Android") != null || varUA.match("coffik15/Android") != null) {
      setOs("android");
    } else if (varUA.match("coffik/iOS") != null) {
      setOs("ios");
    } else {
      setOs("pc");
    }
  }, []);

  if (modal === null) return null;

  return (
    <LoadingArea className={os}>
        <LoadingBox>
            <img src={LoadingIcon} style={{width: '60%', height: '60%'}} alt="loading"/>
        </LoadingBox>
        <Bg/>
    </LoadingArea>
  );
};

const LoadingBox = styled.div`
    position: absolute;
  width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 5em;
    height: 5em;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.6);
    background: -moz-linear-gradient(
      left,
      #ffffff 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: -webkit-linear-gradient(
      left,
      #ffffff 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: -o-linear-gradient(
      left,
      #ffffff 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: -ms-linear-gradient(
      left,
      #ffffff 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: linear-gradient(
      to right,
      #ffffff 10%,
      rgba(255, 255, 255, 0) 42%
    );
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  & > div::before {
    width: 50%;
    height: 50%;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
  & > div::after {
    background: #9e9e9e;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
const Bg = styled.div`
  width: 100%;
  height: 100%;
  backdrop-filter: blur(26px);
  -webkit-backdrop-filter: blur(26px);
  background: rgba(0, 0, 0, 0.3);
`;

const LoadingArea = styled.div`
  position: fixed;
    padding: 113px 0px 65px 0px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 100;

  &.ios {
    padding-bottom: 102px;
  }
`;
export default Loading;
